import { FORMS, FormTypes } from '@/constants/forms';
import { captureException } from '@sentry/core';

export const handleResponse = (response: any, formName: FormTypes) => {
  const isSpecificErrorMessage = (
    receivedErrors: string[],
    messages: { reason: string; message: string; isSuccess: boolean }[],
  ) => {
    const responseMessage = messages.find(({ reason }) =>
      receivedErrors.some((error) => error.toLowerCase().includes(reason.toLowerCase())),
    );

    if (responseMessage?.message) {
      return {
        isSuccess: responseMessage?.isSuccess,
        message: responseMessage?.message,
      };
    } else {
      captureException(new Error(`${formName} form: Failed to send request. ${FORMS[formName].errorMessage}`));

      return {
        isSuccess: false,
        message: FORMS[formName].errorMessage,
      };
    }
  };

  const errors = response.errors;

  if (errors && Array.isArray(errors)) {
    if (!errors.length) {
      return {
        isSuccess: true,
        message: FORMS[formName].successMessage,
      };
    }

    return isSpecificErrorMessage(errors, FORMS[formName].specificErrorMessages);
  }

  return {
    isSuccess: true,
    message: FORMS[formName].successMessage,
  };
};
