import { Controller, FieldValues } from 'react-hook-form';
import { ITextField } from './types';
import clsx from 'clsx';

export const TextField = <T extends FieldValues>({
  name,
  label,
  placeholder,
  disabled,
  testId,
  control,
  labelClassname,
  classname,
  inputClassname,
  dark,
}: ITextField<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
        return (
          <div
            className={clsx('w-full flex flex-col', {
              [`${classname}`]: classname,
            })}
            data-testid={testId}
          >
            {label && (
              <label
                className={clsx(`form-label mb-[6px]`, {
                  [`${labelClassname}`]: labelClassname,
                })}
              >
                {label}
              </label>
            )}

            <input
              value={value}
              onChange={onChange}
              disabled={disabled}
              placeholder={placeholder}
              onBlur={onBlur}
              data-testid={`${testId}-input`}
              className={clsx(
                `
                form-input 
                w-full text-sm px-sm py-[10px] leading-xl 
                border-[1px] ${dark ? 'border-gray50' : 'border-grey-1'} rounded-lg shadow-sm  
                placeholder:text-black-6 
                ring ring-offset-0 ring-0
                focus:outline-none
                focus:border-grey-1 
                focus:ring-pink
                focus:shadow-none
                focus:ring-4 
              `,
                {
                  [`${inputClassname}`]: inputClassname,
                  'border-red-error': error,
                  ['disabled:text-black-6 disabled:bg-black-3']: !dark,
                  ['disabled:text-white disabled:bg-black-7 disabled:opacity-[0.5]']: dark,
                },
              )}
            />
            <div className="w-full min-h-[24px] flex items-center">
              {error && (
                <p
                  data-testid={`${testId}-error-message`}
                  className="w-full text-sm text-red-error text-right"
                >
                  {error.message}
                </p>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};
