import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';

export const maskPhoneNumber = (value: string) => {
  const regexForMasks = /(\d{0,3})(\d{0,3})(\d{0,4})/;
  const coincidences = value.replace(/\D/g, '').match(regexForMasks);

  if (coincidences && coincidences[1].length !== 0 && !coincidences[2]) {
    return `(${coincidences[1]}`;
  }
  if (coincidences && coincidences[2] && !coincidences[3]) {
    return `(${coincidences[1]}) ${coincidences[2]}`;
  }
  if (coincidences && coincidences[3]) {
    return `(${coincidences[1]}) ${coincidences[2]} ${coincidences[3]}`;
  }

  return '';
};

export const guessDefaultCountryByIp = (setDefaultCountry: Dispatch<SetStateAction<string>>) => {
  axios
    .get('https://ipapi.co/json/')
    .then((response) => {
      setDefaultCountry(response?.data?.country_code);
    })
    .catch(() => {
      setDefaultCountry('US');
    });
};

export const formatPhoneNumber = (phoneNumber: string) => phoneNumber.replace(/[^0-9]/g, '');
