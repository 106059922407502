import { captureException } from '@sentry/core';

export interface IBaseResponse {
  errors: Array<string>;
  submissionStatus: 0 | 1;
  success: boolean;
}

export interface IContactForm {
  additionalInfo: string;
  companyName: string;
  email: string;
  firstName: string;
  'g-recaptcha-response': string;
  lastName: string;
  leadCampaign: string;
  leadSource: string;
  phone: string;
  valid: number;
}

export interface INotifyMeForm {
  analytics_client_id: string;
  email: string;
  eventName: string;
  firstName: string;
  'g-recaptcha-response': string;
  lastName: string;
  leadCampaign: string;
  leadSource: string;
  'personal-data-agreement': string;
  popupType: string;
  valid: number;
}

export interface IVacancyForm {
  additionalInfo: string;
  attachments: Array<File>;
  email: string;
  englishLevel: string;
  firstName: string;
  'g-recaptcha-response': string;
  lastName: string;
  leadCampaign: string;
  leadSource: string;
  'personal-data-agreement': string;
  phone: string;
  residenceCountry: string;
  valid: number;
}

class FormService {
  async contactForm(formData: IContactForm) {
    try {
      const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_FORM_URL}/contact-form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      return await response.json();
    } catch (error) {
      captureException(
        new Error('Contact us form: Failed to send request', {
          cause: error,
        }),
      );
      throw new Error('Failed to send request');
    }
  }

  async notifyMeForm(formData: INotifyMeForm) {
    try {
      const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_FORM_URL}/notify-participant`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      return await response.json();
    } catch (error) {
      captureException(
        new Error('Notify me form: Failed to send request', {
          cause: error,
        }),
      );
      throw new Error('Failed to send request');
    }
  }

  async postAttachedFile(file: File) {
    try {
      const response = await fetch(`${process.env.NEXT_PUBLIC_ARTIFACT_URL}`, {
        method: 'POST',
        body: JSON.stringify({
          filetype: file.type,
        }),
      });

      const data = await response.text();
      const { url, id } = JSON.parse(data);

      return { url, id };
    } catch (error) {
      captureException(
        new Error('Apply vacancy form: Failed to post attached file', {
          cause: error,
        }),
      );
      throw new Error(`Failed to get attachment URL: ${error}`);
    }
  }

  async putAttachedFiles(file: File, url: string, id: string) {
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
          'Content-Disposition': `inline;filename=${encodeURI(file.name)}`,
        },
        body: file,
      });

      if (response.status !== 200) {
        captureException(new Error('Apply vacancy form: Failed to put attached file'));
        throw new Error(`Failed to send request`);
      }

      return id;
    } catch (error) {
      captureException(
        new Error('Apply vacancy form: Failed to put attached file', {
          cause: error,
        }),
      );
      throw new Error(`Failed to send request: ${error}`);
    }
  }

  async applyVacancy(formData: IVacancyForm) {
    try {
      const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_FORM_URL}/vacancy-form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      return await response.json();
    } catch (error) {
      captureException(
        new Error('Apply vacancy form: Failed to send request', {
          cause: error,
        }),
      );

      throw new Error(`Failed to send request: ${error}`);
    }
  }
}

export const leadsService = new FormService();
