import { MONTHS } from './dates';

const POLICY_DATE: string = '11.11.2024';

function formatDateToShortMonthString(dateString: string): string {
  const [day, month, year] = dateString.split('.');
  const formattedMonth = Object.values(MONTHS).find((m) => (m.order + 1).toString() === month)?.alias;

  return `${day} ${formattedMonth} ${year}`;
}

const formattedDate = formatDateToShortMonthString(POLICY_DATE);
const POLICY_VERSION = `Privacy Policy and Terms of Use. Version: 0.1. Date: ${POLICY_DATE}.`;
const POLICY_TITLE = `Updated ${formattedDate}`;

export { POLICY_VERSION, POLICY_TITLE };
