import { Controller, FieldValues } from 'react-hook-form';
import { ITextAreaField } from './types';
import clsx from 'clsx';

export const TextAreaField = <T extends FieldValues>({
  name,
  label,
  placeholder,
  disabled,
  testId,
  control,
  labelClassname,
  inputClassname,
  dark,
  rows = 5,
}: ITextAreaField<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
        return (
          <div
            className="w-full flex flex-col"
            data-testid={testId}
          >
            <label
              className={clsx(`form-label mb-[6px]`, {
                [`${labelClassname}`]: labelClassname,
              })}
            >
              {label}
            </label>
            <textarea
              value={value}
              onChange={onChange}
              disabled={disabled}
              placeholder={placeholder}
              data-testid={`${testId}-textarea`}
              rows={rows}
              onBlur={onBlur}
              className={clsx(
                `
                form-textarea
                w-full text-sm px-sm py-[10px] leading-xl resize-none
                border-[1px] ${dark ? 'border-gray50' : 'border-grey-1'} rounded-lg shadow-sm  
                placeholder:text-black-6 
                ring ring-offset-0 ring-0
                focus:outline-none
                focus:border-grey-1 
                focus:ring-pink 
                focus:shadow-none
                focus:ring-4
              `,
                {
                  'border-red-error': error,
                  [`${inputClassname}`]: inputClassname,
                  ['disabled:text-black-6 disabled:bg-black-3']: !dark,
                  ['disabled:text-white disabled:bg-black-7 disabled:opacity-[0.5]']: dark,
                },
              )}
            />
            <div className="w-full min-h-[24px] flex items-center">
              {error && (
                <p
                  data-testid={`${testId}-error-message`}
                  className="w-full text-sm text-red-error text-right"
                >
                  {error.message}
                </p>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};
