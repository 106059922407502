import { setCookie } from 'cookies-next';
import { GCLID } from '@/constants/gclid';
import { HISTORY_SOURCE } from '@/constants/localStorage';

export const setGclid = () => {
  const expirationDuration = 24 * 60 * 60 * 1000;
  const expirationDate = new Date(Date.now() + expirationDuration);
  const cookieName = 'gclid';
  const matches = document.URL.match(/gclid=([^&,^]*)/);

  if (matches !== null) {
    const gclid = matches[1];

    if (gclid) {
      setCookie(cookieName, gclid, { expires: expirationDate });
    }
  }
};

export const getGclid = (gclid: string | undefined, http_referer: string | null) => {
  if (gclid && gclid.length) {
    return GCLID.PAID_SEARCH;
  }

  if (http_referer && http_referer.indexOf('linkedin.com') !== -1) {
    localStorage.removeItem(HISTORY_SOURCE);

    return GCLID.LINKEDIN_ADS;
  }

  return GCLID.ORGANIC_SEARCH;
};
