export const MONTHS = {
  january: {
    order: 0,
    name: 'January',
    alias: 'Jan',
  },
  february: {
    order: 1,
    name: 'February',
    alias: 'Feb',
  },
  march: {
    order: 2,
    name: 'March',
    alias: 'Mar',
  },
  april: {
    order: 3,
    name: 'April',
    alias: 'Apr',
  },
  may: {
    order: 4,
    name: 'May',
    alias: 'May',
  },
  june: {
    order: 5,
    name: 'June',
    alias: 'Jun',
  },
  july: {
    order: 6,
    name: 'July',
    alias: 'Jul',
  },
  august: {
    order: 7,
    name: 'August',
    alias: 'Aug',
  },
  september: {
    order: 8,
    name: 'September',
    alias: 'Sep',
  },
  october: {
    order: 9,
    name: 'October',
    alias: 'Oct',
  },
  november: {
    order: 10,
    name: 'November',
    alias: 'Nov',
  },
  december: {
    order: 11,
    name: 'December',
    alias: 'Dec',
  },
};

export const DAYS_OF_WEEK = {
  sunday: {
    order: 0,
    name: 'Sunday',
    alias: 'Sun',
  },
  monday: {
    order: 1,
    name: 'Monday',
    alias: 'Mon',
  },
  tuesday: {
    order: 2,
    name: 'Tuesday',
    alias: 'Tue',
  },
  wednesday: {
    order: 3,
    name: 'Wednesday',
    alias: 'Wed',
  },
  thursday: {
    order: 4,
    name: 'Thursday',
    alias: 'Thu',
  },
  friday: {
    order: 5,
    name: 'Friday',
    alias: 'Fri',
  },
  saturday: {
    order: 6,
    name: 'Saturday',
    alias: 'Sat',
  },
};

export const DAYS_IN_YEAR = 365;

export const DAYS_IN_WEEK = 7;

export const TIME = {
  secondsInHour: 3600,
  minutesInHour: 60,
  millisecondsInFiveSeconds: 5000,
};

export const HOLIDAYS = [
  {
    name: 'New Year’s Day',
    month: MONTHS.january.order,
    day: 1,
  },
  {
    name: 'Independence Day',
    month: MONTHS.july.order,
    day: 4,
  },
  {
    name: 'Veterans Day',
    month: MONTHS.november.order,
    day: 11,
  },
  {
    name: 'Christmas Day',
    month: MONTHS.december.order,
    day: 25,
  },
];

export const FLOATING_HOLIDAYS = [
  {
    name: 'Martin Luther King Jr. Day',
    month: MONTHS.january.order,
    dayOfWeek: DAYS_OF_WEEK.monday.order,
    order: 3,
    isLast: false,
  },
  {
    name: 'Presidents Day',
    month: MONTHS.february.order,
    dayOfWeek: DAYS_OF_WEEK.monday.order,
    order: 3,
    isLast: false,
  },
  {
    name: 'Memorial Day',
    month: MONTHS.may.order,
    dayOfWeek: DAYS_OF_WEEK.monday.order,
    order: 1,
    isLast: true,
  },
  {
    name: 'Labor Day',
    month: MONTHS.september.order,
    dayOfWeek: DAYS_OF_WEEK.monday.order,
    order: 1,
    isLast: false,
  },
  {
    name: 'Columbus Day',
    month: MONTHS.october.order,
    dayOfWeek: DAYS_OF_WEEK.monday.order,
    order: 2,
    isLast: false,
  },
  {
    name: 'Thanksgiving Day',
    month: MONTHS.november.order,
    dayOfWeek: DAYS_OF_WEEK.thursday.order,
    order: 4,
    isLast: false,
  },
];

export const USA_OFFICE_TIMEZONE = 'US/Central';

export const OFFICE_WORKING_TIME = {
  from: 9,
  to: 13,
};

export const USER_AVAILABLE_TIME = {
  from: 9,
  to: 21,
};

export const MEETING_TRANSFER_DAYS = {
  afternoon: 2,
  morning: 1,
};
